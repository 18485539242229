import React from 'react';
// @ts-ignore
import { ReactComponent as LogoWTagline } from '../../assets/vectors/LogoWTagline.svg';

const LandingHeader = () => {
    return (
        <header className="py-2 md:py-4">
            <div className="flex items-center justify-center">
                <LogoWTagline width={250} height={41} />
            </div>
        </header>
    );
};

export default LandingHeader;
