import loadable from '@loadable/component';

import React, { useEffect } from 'react';
import SEOLayout from '../components/layouts/SEOLayout';
import Hero from '../components/landing/Hero';
import Problem from '../components/landing/Problem';
import Solution from '../components/landing/Solution';
// import RetailersCloud from '../components/landing/RetailersCloud';
const RetailersCloud = loadable(() => import('../components/landing/RetailersCloud'));
// import Footer from '../components/landing/Footer';
const Footer = loadable(() => import('../components/landing/Footer'));

const Index = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '/tailwind.css';  // Path to Tailwind CSS file in public folder
    link.id = 'tailwind-css';

    if (!document.getElementById('tailwind-css')) {
      document.head.appendChild(link);
    }

    return () => {
      const existingLink = document.getElementById('tailwind-css');
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, []);

  return (
    <SEOLayout pageTitle="Huglo Comparison App - Compare your electricity and save">
      <Hero />
      <Problem />
      <Solution />
      <RetailersCloud />
      <Footer />
    </SEOLayout>
  );
};

export default Index;
