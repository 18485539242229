import React from 'react';

const Solution = () => {
    return (
        <section className="pt-4 py-10 bg-white sm:py-16 md:py-12 lg:py-8 bg-gray-50">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto text-center">
                <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Huglo Comparison App helps cut through all that</h2>
                <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">without having to rely on commercial comparison websites who only compare retailer plans that ensure they earn a referral fee</p>
            </div>

            <div className="relative mt-12 lg:mt-20">
                <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                    <img className="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
                </div>

                <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                    <div>
                        <div className="flex items-center justify-center w-16 h-16 mx-auto bg-customOrange border-2 border-gray-200 rounded-full shadow">
                            <span className="text-xl font-semibold text-gray-700"> 1 </span>
                        </div>
                        <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Upload bill</h3>
                        <p className="mt-4 text-base text-gray-600">Upload your bill and we’ll sort out the details. <strong>If you don’t have a bill, enter some brief details.</strong></p>
                    </div>

                    <div>
                        <div className="flex items-center justify-center w-16 h-16 mx-auto bg-customOrange border-2 border-gray-200 rounded-full shadow">
                            <span className="text-xl font-semibold text-gray-700"> 2 </span>
                        </div>
                        <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Get results</h3>
                        <p className="mt-4 text-base text-gray-600">Get the best plans for your needs and see how they compare.</p>
                    </div>

                    <div>
                        <div className="flex items-center justify-center w-16 h-16 mx-auto bg-customOrange border-2 border-gray-200 rounded-full shadow">
                            <span className="text-xl font-semibold text-gray-700"> 3 </span>
                        </div>
                        <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">Explore and choose</h3>
                        <p className="mt-4 text-base text-gray-600">Review the details and click straight through to the retailer to sign up.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Solution;
