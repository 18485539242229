import React from 'react';
// @ts-ignore
import HighVoltage from '../../assets/images/high-voltage.png';
// @ts-ignore
import QuestionMark from '../../assets/images/question-mark.png';
// @ts-ignore
import Doc from '../../assets/images/doc.png';
// @ts-ignore
import AlarmClock from '../../assets/images/alarm-clock.png';
// @ts-ignore
import SandClock from '../../assets/images/sand-clock.png';
// @ts-ignore
import Sun from '../../assets/images/sun.png';
// @ts-ignore
import MoneyFly from '../../assets/images/money-fly.png';
// @ts-ignore
import Chart from '../../assets/images/chart.png';
// @ts-ignore
import Headache from '../../assets/images/headache.png';

const Problem = () => {
    return (
        <section className="pt-4 pb-8 bg-problemBg sm:pt-6 sm:pb-12 lg:pt-8 lg:pb-16">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h2 className="mb-6 text-2xl font-semibold text-center text-problemText sm:text-3xl lg:text-4xl">
                    Energy bills are complex 
                </h2>
                <div className="grid grid-cols-1 px-4 mx-auto text-center sm:px-0 lg:max-w-full lg:grid-cols-5 gap-x-4 lg:gap-x-8 gap-y-8">
                    <div>
                        <div className="flex justify-center items-center space-x-1">
                            <img src={HighVoltage} alt="high-voltage" className="w-auto h-8 lg:h-10"/>
                            <img src={QuestionMark} alt="question-mark" className="w-auto h-8 lg:h-10"/>
                        </div>
                        <h3 className="mt-3 text-sm font-medium text-problemText lg:mt-4">Energy bills are confusing and hard to understand</h3>
                    </div>

                    <div>
                        <div className="flex justify-center items-center space-x-1">
                            <img src={Chart} alt="chart" className="w-auto h-8 lg:h-10"/>
                            <img src={SandClock} alt="sand-clock" className="w-auto h-8 lg:h-10"/>
                        </div>
                        <h3 className="mt-3 text-sm font-medium text-problemText lg:mt-4">Managing energy data manually with spreadsheets is time-consuming and difficult</h3>
                    </div>

                    <div>
                        <div className="flex justify-center items-center space-x-1">
                            <img src={Sun} alt="sun" className="w-auto h-8 lg:h-10"/>
                            <img src={MoneyFly} alt="money-fly" className="w-auto h-8 lg:h-10"/>
                        </div>
                        <h3 className="mt-3 text-sm font-medium text-problemText lg:mt-4">Solar energy plans are tricky; high usage or feed-in tariffs can lead to unexpected costs</h3>
                    </div>
        
                    <div>
                        <div className="flex justify-center items-center space-x-1">
                            <img src={AlarmClock} alt="alarm-clock" className="w-auto h-8 lg:h-10"/>
                            <img src={HighVoltage} alt="high-voltage" className="w-auto h-8 lg:h-10"/>
                        </div>
                        <h3 className="mt-3 text-sm font-medium text-problemText lg:mt-4">Smart meters charge based on both usage and time of day, adding complexity</h3>
                    </div>

                    <div>
                        <div className="flex justify-center items-center space-x-1">
                            <img src={Doc} alt="doc" className="w-auto h-8 lg:h-10"/>
                            <img src={Headache} alt="headache" className="w-auto h-8 lg:h-10"/>
                        </div>
                        <h3 className="mt-3 text-sm font-medium text-problemText lg:mt-4">Too many energy plans available, making it hard to choose the right one</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Problem;
